.bg-icons {
    font-size: 16px;
    line-height: 1;
}

.e-btngrp-red::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><rect x='2' y='4' width='14' height='14' fill='red'/></svg>");
}
    
.e-btngrp-orange::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><rect x='2' y='4' width='14' height='14' fill='orange' /></svg>");
}
    
.e-btngrp-yellow::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><rect x='2' y='4' width='14' height='14' fill='yellow' /></svg>");
}

.e-btngrp-none::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><rect x='2' y='4' width='14' height='14' fill='lightgray' /></svg>");
}

.e-btngrp-red-on::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><rect x='2' y='4' width='14' height='14' fill='red' /><path d='M3 12l4 4 8-8' stroke='black' stroke-width='3' fill='none' /></svg>");
}
    
.e-btngrp-orange-on::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><rect x='2' y='4' width='14' height='14' fill='orange' /><path d='M3 12l4 4 8-8' stroke='black' stroke-width='3' fill='none' /></svg>");
}
    
.e-btngrp-yellow-on::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><rect x='2' y='4' width='14' height='14' fill='yellow' /><path d='M3 12l4 4 8-8' stroke='black' stroke-width='3' fill='none' /></svg>");
}

.e-btngrp-none-on::before {
    content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18'><rect x='2' y='4' width='14' height='14' fill='lightgray' /><path d='M3 12l4 4 8-8' stroke='black' stroke-width='3' fill='none' /></svg>");
}



.button-group-btn {
    font-size: 16px; /* doesn't do anything */
    font-weight: bold;
    text-transform: none;
}

.button-group-btn_on {
    font-size: 16px; /* doesn't do anything */
    font-weight: bold;
    text-transform: uppercase;
}


.button-group-section {
    width: 620px;
    margin-top: 8px;
    margin-bottom: 5px;
    margin-left: 5px;
}